.Hero {
  background-image: url("https://storage.googleapis.com/communityrootbucket/hero-kids-dark.png"); /* The image used */
  background-repeat: no-repeat;
  background-size: cover;
  background-color: transparent;
  background-position: 50% 50%;
  /* padding-top: 100px; */
  height: 100%;
  width: 100%;
  position: absolute;
}

.HeroDimmer .ui.dimmer {
  background-color: rgba(0, 0, 0, 0.7);
  padding: 0;
}

.HeroDimmer .menu .item,
.HeroDimmer .menu a.item {
  padding: 1.25em !important;
}

.HeroDimmer .content {
  width: 100%;
  height: 100%;
}

.HeroDimmer .ui.secondary.inverted.menu .active.item {
  background: rgba(255, 255, 255, 0);
}

.card {
  display: flex;
  min-width: 100%;
  min-height: 200px;
  overflow-x: auto;
  align-items: center;
  text-align: center;
}

.card::-webkit-scrollbar {
  display: none;
}
.card--content {
  background-color: #fff;
  min-width: 300px;
  margin: 5px;
  box-shadow: 3px 5px rgba(255, 255, 255, 0.05);
  max-width: 50%;
}

.ui.header.middle-header {
  font-size: 3.375rem;
  font-weight: 300;
}
/*   
  .card--content img {
    width: 100%;
    height: 100%;
  } */
