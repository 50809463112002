.ui.vertical.segment.hero-banner {
  background-image: url(https://storage.googleapis.com/communityrootbucket/hero-kids-dark.jpg);
  background-size: cover;
  background-position: 50% 0;
  background-repeat: no-repeat;
  min-height: 540px;
  padding: 0;
  width: 100%;
  margin-top: 72px;
}
